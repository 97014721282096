html,
body {
  scroll-behavior: smooth;
  background-color: "white";
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

a.MuiButton-contained:hover {
  color: rgba(255, 255, 255, 1)
}

* {
  box-sizing: border-box;
}

.activeNavLink {
  background-color: #f2f2f2 !important;
  pointer-events: none;
}

.rounded {
  border-radius: 25px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fadeInUp {
  animation: fadeInUp .3s ease;
}

.MuiSnackbarContent-message {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
    -webkit-transform: translate(0, 20px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
}

.pulse {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(220, 53, 69, 1);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  border: 3px solid rgba(255, 255, 255, .7);
}

.timeElement {
  color: #363636;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 30px;
  font-weight: bold;
}

.bigger {
  width: 60px;
}

.socialLink {
  color: #363636 !important;
  background-color: white !important;
  margin-right: 8px !important;
  border-radius: 20px !important;
  width: 35px !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 1);
  }

  70% {
    box-shadow: 0 0 0 calc(16*1px*1) rgba(220, 53, 69, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
  }
}